@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

* {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  box-sizing: border-box;
  letter-spacing: 1.2px;
  font-family: "Open Sans", sans-serif;
}

*::-webkit-scrollbar {
  display: none;
}

:root {
  --black: #000000;
  --white: #ffffff;
  --off-white: #f5efe7;
  --grey: #bfccb5;
  --red: #d21312;
  --green: #0bbb1a;
  --light-transparent: rgba(255, 255, 255, 0.25);
  --lighter-transparent: rgba(255, 255, 255, 0.7);
  --dark-transparent: rgba(0, 0, 0, 0.25);
  --darker-transparent: rgba(0, 0, 0, 0.7);
  /* --linear-background: repeating-linear-gradient(
    45deg,
    var(--light-transparent),
    var(--dark-transparent) 2px
  ); */
}

/* body {
  background: var(--linear-background);
} */

section {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 10;
  /* background: var(--linear-background); */
}

a,
button,
li,
input {
  all: unset;
}

.separation-line {
  height: 1px;
  width: 90%;
  background: var(--red);
  margin: 10px 0;
}

/* navbar component */

.navbar .nav-active {
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
}

.navbar {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  height: 15vh;
  width: 100%;
  z-index: 999;
  backdrop-filter: blur(2.5px);
  color: var(--white);
  background: var(--dark-transparent);
}

.navbar .nav-logo {
  height: 2rem;
  padding-left: 3rem;
  cursor: pointer;
}

.navbar ul {
  /* width: 50%; */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  padding: 0 3rem;
}

.navbar ul li {
  margin: 0 15px;
}

.navbar ul li:last-child {
  margin: 0 0 0 15px;
}

.navbar .nav-link {
  font-size: 1.3rem;
  cursor: pointer;
}

.navbar .last-nav-link {
  background: var(--red);
  color: var(--white);
  padding: 5px 10px;
  border-radius: 5px;
}

.navbar .toggler {
  display: none;
}

/* home component */

/* #home {
  background: var(--linear-background);
} */

#home .bg-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "morning1 morning2"
    "rr speech";
  width: 100%;
  height: 100%;
  position: relative;
}

#home .bg-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#home .bg-container::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}

#home #morning1 {
  grid-area: morning1;
}
#home #morning2 {
  grid-area: morning2;
}
#home #rr {
  grid-area: rr;
}
#home #speech {
  grid-area: speech;
}

#home #logo {
  grid-area: 1 / 1 / 3 / 3;
  z-index: 1;
  align-self: center;
  justify-self: center;
  width: 450px;
  max-width: 100%;
  height: auto;
  backdrop-filter: blur(2px);
  border-radius: 15px;
  position: relative;
  bottom: 40px;
  box-shadow: 1px 1px 5px var(--black);
}

#home .home-container {
  width: 100%;
  min-height: 50vh;
  line-height: 1.25;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 25px;
  text-align: center;
}

#home .home-container h1,
#home .home-container h2,
#home .home-container h3 {
  overflow: hidden;
}

#home .home-container h1 {
  font-size: 2.7rem;
  color: var(--red);
}

#home .home-container h2 {
  font-size: 2rem;
  margin: 30px 0;
}

#home .home-container h3 {
  font-size: 1.5rem;
}

/* home offers */

#home .offers-area {
  width: 100%;
  background: url("./assets/seder-pic-2.webp");
  background-size: cover;
  background-position: 50%;
  position: relative;
}

#home .offers-area::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 25;
}

#home .offers-header-container {
  width: 100%;
  /* background: var(--black); */
  text-align: center;
}

#home .offers-header {
  color: var(--white);
  font-size: 2rem;
  margin: 50px 0;
  position: relative;
  z-index: 50;
}

#home .offers-container {
  width: 100%;
  /* background: var(--black); */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 50px;
}

#home .offers-container .offer {
  width: 350px;
  height: 400px;
  position: relative;
  background: var(--lighter-transparent);
  backdrop-filter: blur(5px);
  color: var(--black);
  margin: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 10px;
  text-align: center;
  z-index: 50;
}

#home .offers-container .offer .offers-num-container {
  position: relative;
  left: 15px;
  line-height: 1;
  align-self: flex-start;
  overflow: hidden;
}

#home .offers-container .offer div .offers-num {
  font-size: 2em;
  color: rgba(246, 9, 9, 0.4);
  pointer-events: none;
  z-index: 25;
  letter-spacing: 0;
}

/* #home .offers-container h3,
#home .offers-container .info-container {
  z-index: 50;
  backdrop-filter: blur(1px);
} */

#home .offers-container h3 {
  font-size: 1.75rem;
  color: var(--red);
}

#home .offers-container .info-container {
  min-height: 70%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

#home .offers-container p {
  font-size: 1rem;
  font-weight: bold;
}

#home .offers-container span {
  font-size: 0.8rem;
  font-weight: bold;
}

/* button area */

#home .btn-container {
  width: 100%;
  /* background: var(--linear-background); */
  display: flex;
  align-items: center;
  justify-content: center;
}

#home .btn-container button {
  font-size: 2rem;
  color: var(--white);
  background: var(--red);
  padding: 10px 15px;
  border-radius: 5px;
  margin: 10vh 0;
  text-align: center;
}

/* testimonials area */

.testimonials-container {
  width: 100%;
  min-height: 60vh;
  /* background: var(--linear-background); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonials-container .carousel-container {
  /* margin-bottom: 10vh; */
  z-index: 100;
  width: 80%;
  position: relative;
  height: 400px;
  width: 100%;
  overflow: hidden;
  text-align: center;
  background: var(--black);
}

.testimonials-container .review-container {
  width: 100%;
  height: 400px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  overflow: auto;
}

.testimonials-container .review-text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 15px 20px;
  color: var(--white);
}

.testimonials-container .review-text cite {
  font-size: 2rem;
  color: var(--red);
}

.testimonials-container .review-text q {
  font-size: 1.2rem;
}

.testimonials-container .review-text q::before,
.testimonials-container .review-text q::after {
  color: var(--red);
}

.review-text q::before,
.review-text q::after {
  font-size: 42px;
  color: var(--gold);
  padding: 0 5px;
}

.testimonials-container .review-btn-container {
  position: absolute;
  left: 0;
  bottom: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
}

.testimonials-container button img {
  width: 65px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  padding: 5px;
}

.flip {
  transform: scaleX(-1);
}

/* carousel transition animations */

.fade-enter {
  opacity: 0;
  transform: scale(0.9);
}

.fade-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

/* call to action area */

#home .cta {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--black);
}

#home .cta .cta-header {
  font-size: 2.5rem;
  color: var(--white);
  margin: 50px 0 0;
  text-align: center;
  padding: 0 25px;
}

#home .cta .btn-container {
  background: var(--black);
}

/* about component */

#about .separation-line {
  margin: 0;
}

/* #about .bubble-one, #about .bubble-two, #about .bubble-three {
  position: fixed;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: rgba(246, 9, 9, 0.4);
  z-index: 5;
}

#about .bubble-one {
  left: -150px;
  top: 10vh;
}

#about .bubble-two {
  right: -150px;
  bottom: 10vh;
}

#about .bubble-three {
  top: 50%;
} */

#about {
  justify-content: flex-start;
  /* background: var(--linear-background); */
}

#about .about-header-container {
  width: 100%;
  text-align: center;
  margin-top: 20vh;
  padding: 0 25px;
  z-index: 50;
}

#about .about-header-container h1 {
  font-size: 2.5rem;
}

#about .about-header-container h1 span {
  font-size: 2.7rem;
  color: var(--red);
}

#about .about-outer-container {
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 50;
}

#about .about-container-header {
  margin: 10vh 0;
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
}

#about .about-container {
  width: 100%;
  max-width: 1400px;
}

#about .about-container .about-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 10vh 0;
}

#about .about-container .row {
  flex-direction: row;
}

#about .about-container .row-reverse {
  flex-direction: row-reverse;
}

#about .about-container .text-container,
#about .about-container .img-container {
  height: 80vh;
}

#about .about-container .text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  width: 30%;
}

#about .about-container .text-container h3 {
  font-size: 2rem;
  color: var(--red);
  margin-bottom: 30px;
  width: 100%;
}

#about .about-container .text-container p {
  font-size: 1rem;
  color: var(--black);
  font-weight: bold;
  width: 100%;
}

#about .about-container .img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  overflow: hidden;
}

#about .about-container .img-container img {
  width: 100%;
  border-radius: 5px;
}

/* about rebbeim area */

#about .about-rebbeim-container {
  width: 100%;
  text-align: center;
  margin-bottom: 20vh;
  padding: 0 25px;
  z-index: 50;
}

#about .about-rebbeim-container h1 {
  font-size: 2.5rem;
}

#about .card-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  z-index: 50;
}

#about .card-container .card {
  width: 350px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  background: var(--white);
  margin: 20px;
  border-radius: 5px;
}

#about .card-container .card h4 {
  width: 100%;
  padding: 0 5px;
  font-size: 1.25rem;
  color: var(--red);
  text-align: center;
}

#about .card-container .card h5 {
  width: 100%;
  padding: 0 5px;
  font-size: 1rem;
  color: var(--black);
  text-align: center;
}

#about .card-container .card p {
  width: 100%;
  padding: 0 5px;
  font-size: 0.9rem;
  color: var(--black);
  text-align: center;
}

#about .card-container .card img {
  width: 100%;
  max-height: 50%;
  /* margin: 30px 0; */
}

#about .history-header-container {
  width: 100%;
  text-align: center;
  margin: 20vh;
  padding: 0 25px;
  z-index: 50;
}

#about .history-header-container h1 {
  font-size: 2.5rem;
}

#about .history {
  width: 100%;
  text-align: center;
  padding: 0 25px 20vh;
  font-size: 1.35rem;
  z-index: 50;
}

#about .btn-container {
  width: 100%;
  background: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

#about .btn-container button {
  font-size: 2rem;
  color: var(--white);
  background: var(--red);
  padding: 10px 15px;
  border-radius: 5px;
  margin: 20vh 0;
}

#about .media-header-container {
  width: 100%;
  text-align: center;
  margin: 20vh 0 0;
  padding: 0 25px;
  z-index: 50;
}

#about .media-header-container h1 {
  font-size: 2.5rem;
}

#about .media-mentions-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20vh 0;
  z-index: 50;
}

#about .mention {
  margin: 10px;
}

#about .mention a {
  min-width: 100px;
  max-width: 250px;
  height: 55px;
  color: var(--white);
  background: var(--red);
  font-weight: bold;
  border-radius: 15px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
}

#about .mention a:hover img {
  transform: rotate(0) scale(1.2);
}

#about .mention img {
  width: 30px;
  transform: rotate(-90deg);
  transition: 0.5s ease-in-out;
  margin-left: 15px;
}

/* tuition component */

#tuition .img-overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 110vh;
  z-index: 10;
  background: url("./assets/morning-2.webp");
  background-position: 50%;
  background-size: cover;
}

#tuition .tuition-container {
  width: 100%;
  padding-top: 20vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  z-index: 100;
}

#tuition .tuition-info-container {
  width: 50%;
  min-width: 350px;
  background: var(--darker-transparent);
  padding: 15px;
  margin: 25px;
  backdrop-filter: blur(5px);
  text-align: left;
}

#tuition .tuition-info-container h1 {
  width: 100%;
  font-size: 2rem;
  color: var(--red);
  line-height: 1.5;
}

#tuition .tuition-info-container p {
  width: 100%;
  font-size: 1.2rem;
  color: var(--white);
}

#tuition iframe {
  min-width: 400px;
  max-width: 500px;
  max-height: none !important;
  height: 1000px;
  margin: 25px;
}

/* application component */

#application {
  background: url("./assets/seder-pic-3.webp");
  background-size: cover;
  background-position: 50%;
  position: relative;
}

#application .overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
}

#application header {
  z-index: 50;
}

#application .application-header {
  width: 100%;
  text-align: center;
  width: 100%;
  font-size: 2.5rem;
  color: var(--white);
  margin: 20vh 0 8vh;
}

#application .application-container {
  width: 100%;
  min-height: 0vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 25px;
  z-index: 50;
}

#application .application-info {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  text-align: left;
  width: 500px;
  min-height: 450px;
  line-height: 1.5;
  margin: 0 25px 50px;
  background: rgba(250, 250, 250, 0.75);
  color: var(--black);
  padding: 15px;
  border-radius: 15px;
}

#application .application-info h3 {
  width: 100%;
}

#application .application-info ol,
#application .application-info ul {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
}

#application .application-form {
  width: 500px;
  min-height: 450px;
  margin: 0 25px 50px;
  background: rgba(0, 0, 0, 0.75);
  color: var(--white);
  padding: 15px;
  border-radius: 15px;
}

#application .inputBox-container {
  width: 100%;
  min-height: 380px;
  display: grid;
  grid-template-columns: 50% 50%;
  position: relative;
}

#application .inputBox-container .inputBox {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--white);
  transition: background-color 5000s ease-in-out 0s;
}

#application .inputBox-container .inputBox input {
  width: 90%;
  height: 60%;
  border-bottom: 1px solid var(--red);
  font-size: 12px;
}

#application .inputBox-container .inputBox input::placeholder {
  color: var(--white);
  font-size: 12px;
}

#application .inputBoxBtn-Container {
  width: 100%;
  height: 10%;
  text-align: center;
}

#application .inputBoxBtn-Container .inputBoxBtn {
  background: var(--red);
  padding: 5px 10px;
  border-radius: 5px;
  transition: 0.5s ease-in-out;
}

/* contact component */

#contact header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#contact .contact-header h1 {
  font-size: 2.5rem;
  color: var(--black);
  margin: 20vh 0 8vh;
  text-align: center;
}

#contact .contact-page-container {
  width: 100%;
  /* margin: 0 0 20vh; */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
}

#contact .contact-page-container .contact-links-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  width: 500px;
  min-height: 300px;
  margin: 0 25px 50px;
  background: var(--lighter-transparent);
  color: var(--red);
  padding: 15px;
  border-radius: 15px;
}

#contact .contact-page-container .contact-links-container li {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#contact .contact-page-container .contact-links-container li a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 100%;
  text-align: center;
  padding: 10px 15px;
  font-weight: bold;
  cursor: pointer;
}

/* contact form */

#contact .contact-form {
  width: 500px;
  min-height: 300px;
  margin: 0 25px 50px;
  background: rgba(0, 0, 0, 0.75);
  color: var(--white);
  padding: 15px;
  border-radius: 15px;
}

#contact .inputBox-container {
  width: 100%;
  min-height: 220px;
  display: grid;
  grid-template-columns: 50% 50%;
  position: relative;
}

#contact .inputBox-container .inputBox {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#contact .inputBox-container .inputBox:last-child {
  grid-column: span 2;
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--white);
  transition: background-color 5000s ease-in-out 0s;
}

#contact .inputBox-container .inputBox input {
  width: 90%;
  height: 60%;
  border-bottom: 1px solid var(--red);
  font-size: 12px;
}

#contact .inputBox-container .inputBox input::placeholder {
  color: var(--white);
  font-size: 12px;
}

#contact .inputBoxBtn-Container {
  width: 100%;
  height: 10%;
  text-align: center;
}

#contact .inputBoxBtn-Container .inputBoxBtn {
  background: var(--red);
  padding: 5px 10px;
  border-radius: 5px;
  transition: 0.5s ease-in-out;
}

#contact .maps-container {
  width: 100%;
  padding: 10vh 25px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  background: url("./assets/ttec-gathering.webp");
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

#contact .maps-container-header h1 {
  font-size: 2.5rem;
  color: var(--black);
  text-align: center;
}

#contact .iframe-container {
  width: 400px;
  height: 400px;
  margin: 50px;
  background: var(--white);
  border-radius: 25px;
  text-align: center;
}

#contact .iframe-container header h2 {
  padding: 5px 10px;
  font-size: 1.7rem;
}

#contact .maps-container iframe {
  max-width: 100%;
  width: 100%;
  height: 85%;
}

/* footer component */

#footer {
  width: 100%;
  /* min-height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  /* background: var(--linear-background); */
  background-color: var(--white);
  color: var(--white);
  position: relative;
  z-index: 950;
}

#footer .footer-header-container {
  width: 100%;
  text-align: center;
  color: var(--black);
  padding-bottom: 10vh;
}

#footer .footer-header-container h4 {
  font-size: 2.7rem;
  color: var(--red);
  margin-top: 5vh;
}

#footer .footer-header-container h5 {
  font-size: 1.5rem;
  margin-bottom: 10vh;
}

#footer .footer-header-container a {
  font-size: 2rem;
  background: var(--red);
  color: var(--white);
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

#footer .footer-info-container {
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 50% 50%;
  background: var(--black);
}

#footer .footer-info-container .contact-container,
#footer .footer-info-container .address-container {
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

#footer header h6 {
  font-size: 2rem;
  color: var(--red);
}

#footer li a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;
}

#footer li img {
  width: 1.75rem;
  transform: rotate(-90deg);
  transition: 0.5s ease-in-out;
  margin-left: 15px;
  background: var(--red);
  border-radius: 50%;
}

#footer li:hover img {
  transform: rotate(0) scale(1.2);
}

#footer .footer-links-outer-container {
  width: 100%;
  height: 100%;
  background: var(--black);
}

#footer .footer-links-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 0 25px;
  margin: 5vh auto;
  border-radius: 25px;
  background: var(--red);
  width: min-content;
}

#footer .footer-links-container li {
  margin: 15px;
  cursor: pointer;
  font-size: 1.2rem;
}

#footer .website-container {
  width: 100%;
  background: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding-bottom: 5vh;
}

#footer .website-container p {
  font-size: 1.25rem;
}

#footer .website-container a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  cursor: pointer;
}

#footer .website-container a img {
  height: 100%;
}

@media only screen and (max-width: 999px) {
  .navbar {
    padding: 0 15px;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
  }

  .navbar .nav-logo {
    padding-left: 0;
  }

  .navbar .toggler {
    cursor: pointer;
    display: block;
  }

  .navbar .toggler img {
    width: 3rem;
    height: unset;
  }

  .navbar .toggler {
    cursor: pointer;
    display: block;
  }

  .navbar .toggler img {
    width: 3rem;
    height: unset;
  }
  .navbar ul {
    position: fixed;
    top: 15vh;
    right: 0;
    width: 100%;
    height: 85vh;
    flex-direction: row;
    flex-wrap: wrap;
    clip-path: polygon(0% 50%, 100% 50%, 100% 50%, 0% 50%);
    -webkit-backdrop-filter: invert(1) blur(5px);
    /* background: var(--light-transparent); */
    backdrop-filter: invert(1) blur(5px);
    transition: 0.5s ease-in-out;
    transition: 1.5s ease-in-out;
  }

  .navbar ul li {
    font-size: 28px;
    border-bottom: 1px solid transparent;
    color: var(--black);
    border-radius: 5px;
    cursor: pointer;
    transition: 0.5s ease-in-out;
    position: relative;
    bottom: 20px;
    background: var(--red);
    color: var(--white);
    padding: 5px 10px;
    border-radius: 5px;
    margin: 10px;
  }

  /* .navbar ul li,
  .navbar ul li:last-child {
    margin: 0;
  } */

  #home .bg-container {
    height: 110vh;
  }

  #home .testimonials-container {
    min-height: 60vh;
  }

  .testimonials-container .carousel-container {
    height: 400px;
    width: 100%;
    margin-bottom: 10vh;
    border-radius: 0;
  }

  .testimonials-container .review-container {
    height: 400px;
  }

  .testimonials-container .review-text cite {
    font-size: 2rem;
  }

  .testimonials-container .review-text q {
    font-size: 1.2rem;
  }

  /* about component */

  #about .about-container .row {
    flex-direction: column;
  }

  #about .about-container .text-container {
    width: 100%;
    height: unset;
    text-align: center;
    margin-bottom: 50px;
  }

  #about .about-container .img-container {
    width: 100%;
    height: unset;
  }

  #about .about-container .row-reverse {
    flex-direction: column;
  }

  #about .about-rebbeim-container {
    margin-bottom: 8vh;
  }
  /* tuition component */

  #tuition .tuition-info-container {
    width: 80%;
    text-align: center;
  }

  /* application component */

  #application .application-info {
    text-align: center;
  }

  #application .application-info ol,
  #application .application-info ul {
    align-items: center;
  }

  /* tuition component */

  #tuition .img-overlay-container {
    background: url("./assets/rabbi-rudner-2.webp");
    background-position: center;
  }

  /* contact component */

  #contact .maps-container {
    padding: 10vh 0;
  }

  #contact .iframe-container {
    margin: 50px 0;
    width: 350px;
    height: 400px;
  }

  /* footer component */

  #footer .footer-info-container {
    padding: 5vh 25px;
    grid-template-columns: 100%;
  }

  #footer .footer-links-container {
    flex-wrap: wrap;
    border-radius: 0;
    background: var(--red);
    width: 100%;
  }
}

/* tablet sizing */

/* @media only screen and (max-width: 999px) {
  #home .bg-container {
    height: 110vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "morning1 morning2";
  }

  #home #morning1 {
    grid-area: morning1;
    display: block;
  }

  #home #morning2 {
    grid-area: morning2;
    display: block; 
  }

  #home #rr,
  #home #speech {
    display: none;
  }
} */

/* mobile sizing */

@media only screen and (max-width: 600px) {
  /* home component */

  #home .bg-container {
    height: 110vh;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      "rr"
      "speech";
  }

  #home #morning1,
  #home #morning2 {
    display: none;
  }

  #home #logo {
    grid-area: 1 / 1 / 3 / 2;
    width: 90%;
    bottom: 20px;
    background: var(--light-transparent);
  }

  #home .home-container {
    padding: 10vh 25px;
  }

  #home .btn-container button {
    border-radius: 0;
  }

  #home .offers-area {
    background: transparent;
  }

  #home .offers-container .offer {
    width: 90%;
  }

  #home .testimonials-container {
    min-height: 80vh;
  }

  .testimonials-container .carousel-container {
    height: 550px;
    width: 100%;
    margin-bottom: 10vh;
    border-radius: 0;
  }

  .testimonials-container .review-container {
    height: 550px;
  }

  .testimonials-container .review-text cite {
    font-size: 2rem;
  }

  .testimonials-container .review-text q {
    font-size: 1.2rem;
  }

  /* about component */

  #about .about-container .row {
    flex-direction: column;
  }

  #about .about-container .row-reverse {
    flex-direction: column;
  }

  #about .about-container .text-container {
    width: 100%;
    height: unset;
    text-align: center;
    margin-bottom: 50px;
  }

  #about .about-container .img-container {
    width: 100%;
    height: unset;
  }

  #about .about-rebbeim-container {
    margin-bottom: 10vh;
  }

  #about .history-header-container {
    margin: 10vh 0;
  }

  #about .btn-container button {
    margin: 10vh 0;
  }

  #about .media-header-container {
    margin: 10vh 0 0;
  }

  #about .media-mentions-container {
    padding: 10vh 0 0;
  }

  /* application component */

  #application .application-info {
    text-align: center;
  }

  #application .application-info,
  #application .application-form {
    width: 100%;
    margin: 0 0 50px;
  }

  #application .application-info ol,
  #application .application-info ul {
    align-items: center;
  }

  #application .inputBox-container {
    grid-template-columns: 100%;
  }

  #application .inputBox-container .inputBox input {
    min-height: 50px;
  }

  #application .inputBoxBtn-Container {
    margin: 30px 0;
  }

  #contact .contact-header {
    text-align: center;
  }

  #contact .contact-page-container {
    flex-direction: column-reverse;
  }

  #contact .contact-form,
  #contact .contact-page-container .contact-links-container {
    width: 100%;
    border-radius: 0;
    margin: 0;
  }

  #contact .maps-container {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  #contact .maps-container-header h1 {
    background: var(--red);
    color: var(--white);
    border-radius: 5px;
    padding: 5px 10px;
    width: min-content;
  }

  /* footer component */

  /* 
  #footer .footer-header-container h4 {
    margin-top: 0;
  } */

  #footer .footer-info-container {
    padding: 5vh 25px;
    grid-template-columns: 100%;
  }

  #footer .footer-info-container .contact-container li,
  #footer .footer-info-container .address-container li {
    width: 100%;
    text-align: center;
    margin: 30px 0;
  }

  #footer li a {
    flex-direction: column;
  }

  #footer li a p {
    margin-bottom: 15px;
  }

  #footer .footer-links-container {
    width: 100%;
    margin: 5vh 0;
    border-radius: 0;
    flex-wrap: wrap;
  }
}
